"use strict";var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");exports.__esModule=true;exports.default=Gallery;var _taggedTemplateLiteralLoose2=_interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteralLoose"));var _objectWithoutPropertiesLoose2=_interopRequireDefault(require("@babel/runtime/helpers/objectWithoutPropertiesLoose"));var _react=require("@lingui/react");var _client=require("@apollo/client");var _src=require("@bottlebooks/gatsby-theme-base/src");var _react2=_interopRequireDefault(require("react"));var _themeUi=require("theme-ui");var _GalleryLightbox=_interopRequireDefault(require("./GalleryLightbox"));var _templateObject;var _excluded=["images"],_excluded2=["image","title","full","fullPreview","description","className","onClick","to"];function Gallery(_ref){var data=_ref.images,rest=(0,_objectWithoutPropertiesLoose2.default)(_ref,_excluded);// 12 is the optimal number for a grid (12 is the lowest common multiple of 2, 3, and 4):
// - mobile:  6x2 (2 images per row, 6 rows)
// - tablet:  4x3 (3 images per row, 4 rows)
// - desktop: 3x4 (4 images per row, 3 rows)
var maxImages=12;var images=data===null||data===void 0?void 0:data.filter(function(image){return Boolean(image.image);});var mayExpand=images?images.length>maxImages:false;var _React$useState=_react2.default.useState(false),isExpanded=_React$useState[0],expand=_React$useState[1];var _React$useState2=_react2.default.useState(null),currentIndex=_React$useState2[0],showIndex=_React$useState2[1];var nextIndex=currentIndex==null||!(images!==null&&images!==void 0&&images.length)?0:(currentIndex+1)%images.length;var previousIndex=currentIndex==null||!(images!==null&&images!==void 0&&images.length)?0:(currentIndex+images.length-1)%images.length;if(!images)return null;return(0,_themeUi.jsx)(_react2.default.Fragment,null,(0,_themeUi.jsx)(_src.Grid,Object.assign({gap:2,sx:{gridTemplateColumns:"repeat(auto-fill, minmax(180px, 1fr))",// We limit to only three rows if the grid isn't expanded. This works in mobile and desktop.
gridTemplateRows:'auto auto auto',gridAutoRows:isExpanded?undefined:'0',rowGap:0}},rest),images.slice(0,isExpanded?undefined:maxImages).map(function(image,index){return image.image?(0,_themeUi.jsx)(GalleryImage,Object.assign({key:image.image.src},image,{sx:{marginBottom:2,overflow:'hidden'},onClick:function onClick(){return showIndex(index);}})):null;})),mayExpand&&!isExpanded&&(0,_themeUi.jsx)(_src.Button,{variant:"outline",onClick:function onClick(){return expand(true);}},(0,_themeUi.jsx)(_react.Trans,{id:"{0, plural, one {Show # image} other {Show all # images}}",values:{0:images.length}})),isExpanded&&(0,_themeUi.jsx)(_src.Button,{variant:"outline",onClick:function onClick(){return expand(false);}},(0,_themeUi.jsx)(_react.Trans,{id:"Show less"})),currentIndex==null?null:(0,_themeUi.jsx)(_GalleryLightbox.default,{sx:{fontFamily:'text',color:'red'},current:images[currentIndex],next:images[nextIndex],previous:images[previousIndex],onClose:function onClose(){return showIndex(null);},onPrevious:function onPrevious(){return showIndex(previousIndex);},onNext:function onNext(){return showIndex(nextIndex);}}));}function GalleryImage(_ref2){var thumb=_ref2.image,title=_ref2.title,full=_ref2.full,fullPreview=_ref2.fullPreview,description=_ref2.description,className=_ref2.className,onClick=_ref2.onClick,to=_ref2.to,rest=(0,_objectWithoutPropertiesLoose2.default)(_ref2,_excluded2);if(!(thumb!==null&&thumb!==void 0&&thumb.src))return null;return(0,_themeUi.jsx)(_src.Link,{variant:"text",className:className,onClick:onClick,to:to,sx:{position:'relative',display:'block',transition:'outline',outline:'2px solid',outlineColor:'transparent',':hover:is(button,a[href]), :focus:is(button,a[href])':{outlineColor:'primary'}}},(0,_themeUi.jsx)(_src.Image,Object.assign({alt:title||'',title:title// @ts-ignore We check src already, but the other props can safely be ignored
,fluid:Object.assign({},thumb,{sizes:'(max-width: 360px) 100vw, (max-width: 40em) 50vw, 272px'})},rest)),title&&(0,_themeUi.jsx)(_src.Text,{variant:"small",sx:{position:'absolute',bottom:0,left:0,right:0,padding:2,backgroundColor:'overlay',color:'onDark'}// Clamp the text to 3 lines in browsers that support it (Chrome/Firefox), falling back to 45% of the image height.
// @ts-ignore lineClamp needs to be set to a string because theme-ui changes it to pixels. That doesn't match the type of lineClamp.
,css:{maxHeight:'45%',overflow:'hidden',textOverflow:'ellipsis',// The Webkit hack:
display:'-webkit-box',WebkitLineClamp:3,WebkitBoxOrient:'vertical',// Standard line clamping:
lineClamp:'3'}},title));}var fragment="2934491245";Gallery.fragment=(0,_client.gql)(_templateObject||(_templateObject=(0,_taggedTemplateLiteralLoose2.default)(["\n  fragment Gallery on UploadedFile {\n    title\n    image: fluid(maxWidth: 360, maxHeight: 360, crop: FILL) {\n      src\n      srcSet\n      sizes\n      width\n      height\n      aspectRatio\n    }\n    ...GalleryLightbox\n  }\n\n  ","\n"])),_GalleryLightbox.default.fragment);