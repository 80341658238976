"use strict";var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");exports.__esModule=true;exports.default=Image;var _objectWithoutPropertiesLoose2=_interopRequireDefault(require("@babel/runtime/helpers/objectWithoutPropertiesLoose"));var _themeUi=require("theme-ui");var _react=_interopRequireDefault(require("react"));var _cloudinaryCore=require("cloudinary-core");var _excluded=["publicId","cloudName","layout","alt","sizes","options"];var CLOUDINARY_IMAGE_MAX_SIZE=4000;// Maximum width or height.
var cl=new _cloudinaryCore.Cloudinary({cloud_name:'bottlebooks',secure:true});function Image(_ref){var publicId=_ref.publicId,_ref$cloudName=_ref.cloudName,cloudName=_ref$cloudName===void 0?'bottlebooks':_ref$cloudName,layout=_ref.layout,alt=_ref.alt,sizes=_ref.sizes,options=_ref.options,rest=(0,_objectWithoutPropertiesLoose2.default)(_ref,_excluded);if(!publicId)return null;switch(layout){case'fixed':return(0,_themeUi.jsx)("picture",{sx:{justifySelf:'center',position:'relative'}},(0,_themeUi.jsx)("source",Object.assign({type:"image/avif"},getFixedImage(publicId,Object.assign({fetchFormat:'avif'},options)),{sizes:sizes})),(0,_themeUi.jsx)("source",Object.assign({type:"image/webp"},getFixedImage(publicId,Object.assign({fetchFormat:'webp'},options)),{sizes:sizes})),(0,_themeUi.jsx)("img",Object.assign({alt:alt},getFixedImage(publicId,Object.assign({fetchFormat:'png'},options)),rest,{sizes:sizes})));case'constrained':case'fullWidth':default:throw new Error("Image layout: "+layout+" isn't implemented yet.");}}function getFixedImage(publicId,options){return{width:options.width,height:options.height,src:cl.url(publicId,options),srcSet:getFixedSrcSet(publicId,options)};}// This is copied and adapted from the CloudinaryAsset.fixed resolver.
function getFixedSrcSet(publicId,options){var desiredAspectRatio=Number(options.aspectRatio)||1;// If we're cropping, calculate the specified aspect ratio.
if(typeof options.width==='number'&&typeof options.height==='number'){desiredAspectRatio=options.width/options.height;}// Create sizes (in width) for the image. If the width of the
// image is 800px, the sizes would then be: 800, 1200, 1600,
// 2400.
//
// This is enough sizes to provide close to the optimal image size for every
// device size / screen resolution
var fixedSizes=(typeof options.width==='number'?[options.width,options.width*1.5,options.width*2,options.width*3]:[]).map(Math.round)// Filter out sizes larger than the image's width and the Cloudinary image's max size.
.filter(function(width){var calculatedHeight=Math.round(width/desiredAspectRatio);return width<=CLOUDINARY_IMAGE_MAX_SIZE&&calculatedHeight<=CLOUDINARY_IMAGE_MAX_SIZE;});// Sort sizes for prettiness.
// const sortedSizes = sortBy(fixedSizes);
// Create the srcSet.
var srcSet=fixedSizes.map(function(width,i){var resolution;switch(i){case 0:resolution="1x";break;case 1:resolution="1.5x";break;case 2:resolution="2x";break;case 3:resolution="3x";break;default:}var height=Math.round(width/desiredAspectRatio);return cl.url(publicId,Object.assign({},options,{width:width,height:height}))+" "+resolution;}).join(",\n");return srcSet;}