"use strict";exports.__esModule=true;exports.default=getProductFilters;var _core=require("@lingui/core");function getProductFilters(){return{aggregations:{highlights:{title:/*i18n*/{id:'Highlights'},conjunction:false,get:function get(product){var _product$highlights,_product$highlights$v;var highlights=((_product$highlights=product.highlights)===null||_product$highlights===void 0?void 0:(_product$highlights$v=_product$highlights.values)===null||_product$highlights$v===void 0?void 0:_product$highlights$v.map(function(highlight){return highlight||'';}))||[];return[// Exclude 'None'
...highlights.filter(function(highlight){return highlight!=='None';})].filter(Boolean);},size:20},flight:{title:/*i18n*/{id:'Flights'},conjunction:false,multiSelect:false,size:100,get:function get(product){return product.flightName||undefined;}// Excludes nulls
},productType:{title:/*i18n*/{id:'Product types'},conjunction:false,get:function get(product){return product.wineType||// product.spiritType ||
// product.beerType ||
product.productType;},size:20,translations:{WINE:/*i18n*/{id:'Other wine'},SPIRIT:/*i18n*/{id:'Spirit'},Stillwein:/*i18n*/{id:'Wine'},BEER:/*i18n*/{id:'Beer and more'}}},country:{title:/*i18n*/{id:'Countries'},conjunction:false,multiSelect:false,size:100,get:function get(product){return product.country||undefined;}// Excludes nulls
},regionHierarchy:{title:/*i18n*/{id:'Winegrowing regions'},conjunction:false,// multiSelect: false,
size:100,get:function get(product){var _product$regionHierar;return((_product$regionHierar=product.regionHierarchy)===null||_product$regionHierar===void 0?void 0:_product$regionHierar.map(function(e){return e||'';}))||[];}// Excludes nulls
},vintage:{title:/*i18n*/{id:'Vintages'},conjunction:false,size:50,sort:'term',order:'desc',get:function get(product){return product.vintage||undefined;},// Excludes nulls
translations:{NV:/*i18n*/{id:'NV'}}},priceRanges:{title:/*i18n*/{id:'Price ranges'},conjunction:false,sort:'term',// This comes from ProductsTemplate.afterQuery.
get:function get(product){var _product$pricing;return((_product$pricing=product.pricing)===null||_product$pricing===void 0?void 0:_product$pricing.priceRange)||undefined;}// Excludes nulls
},productionMethods:{title:/*i18n*/{id:'Production methods'},conjunction:false,size:20,get:function get(product){var _product$characterist;return(product===null||product===void 0?void 0:(_product$characterist=product.characteristics)===null||_product$characterist===void 0?void 0:_product$characterist.filter(function(value){return['VEGAN','ORGANIC','VEGETARIAN','BIODYNAMIC','KOSHER','SUSTAINABLE'].includes(value||'');}).map(function(e){return e||'';}))||[];},translations:{VEGAN:/*i18n*/{id:'vegan'},ORGANIC:/*i18n*/{id:'organic'},VEGETARIAN:/*i18n*/{id:'vegetarian'},BIODYNAMIC:/*i18n*/{id:'biodynamic'},KOSHER:/*i18n*/{id:'kosher'},SUSTAINABLE:/*i18n*/{id:'sustainable'}}},classification:{title:/*i18n*/{id:'Classifications'},conjunction:false,size:50,get:function get(product){if(!(product!==null&&product!==void 0&&product.classification))return undefined;// Excludes nulls
if(product.classification==='----')return undefined;if(product.classification==='No classification')return undefined;return product.classification;}},specialClassification:{title:/*i18n*/{id:'Special classifications'},conjunction:false,size:50,get:function get(product){if(!(product!==null&&product!==void 0&&product.specialClassification))return undefined;// Excludes nulls
if(product.specialClassification==='----')return undefined;return product.specialClassification;}},designation:{title:/*i18n*/{id:'Designations'},conjunction:false,size:50,get:function get(product){return product.designation||undefined;}// Excludes nulls
},grapeVarieties:{title:/*i18n*/{id:'Grape varieties'},conjunction:false,size:300,get:function get(product){var _product$grapeVarieti;return(_product$grapeVarieti=product.grapeVarieties)===null||_product$grapeVarieti===void 0?void 0:_product$grapeVarieti.map(function(variety){return(variety===null||variety===void 0?void 0:variety.varietyName)||'';}).filter(Boolean);}// Excludes nulls
}// closureTypes: {
//   title: defineMessage({ message: 'Closure types' }),
//   conjunction: false,
//   size: 50,
//   get: (product) => product.bottleVariants?.[0]?.closureType || undefined, // Excludes nulls
// },
},sortings:{byProducerName:{title:/*i18n*/{id:'Producer name'},field:[function(product){var _product$producer,_product$producer2;return(product===null||product===void 0?void 0:(_product$producer=product.producer)===null||_product$producer===void 0?void 0:_product$producer.sortName)||(product===null||product===void 0?void 0:(_product$producer2=product.producer)===null||_product$producer2===void 0?void 0:_product$producer2.name);}],order:'asc',group:'producer.producerId',groupHeader:'producer'},byProductName:{title:/*i18n*/{id:'Product name'},field:['shortName','vintage'],order:['asc','desc']},bySortIndex:{title:/*i18n*/{id:'Sort number'},field:['sortIndex'],order:['asc']}// byExhibitorName: {
//   title: defineMessage({ message: 'Exhibitor name' }),
//   field: [
//     'exhibitor.sortName',
//     'exhibitor.name',
//     'producer.sortName',
//     'producer.name',
//   ],
//   order: 'asc',
//   group: 'exhibitorId',
// },
// byStandNumber: {
//   title: defineMessage({ message: 'Table number' }),
//   field: [
//     sortBy.standName,
//     'exhibitor.sortName',
//     'exhibitor.name',
//     'producer.sortName',
//     'producer.name',
//   ],
//   order: 'asc',
//   group: 'registration.registrationId',
// },
}};}// Sort helper functions
var sortBy={standName:function standName(product){var _product$stand;return(product===null||product===void 0?void 0:(_product$stand=product.stand)===null||_product$stand===void 0?void 0:_product$stand.name.split(/[\s,-]+/).map(function(segment){return segment==='Tisch'?undefined:Number(segment)?segment.padStart(3,'0'):segment;}).filter(Boolean).join(' '))||'999';}};var fragment="3417762052";