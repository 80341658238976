import {
  catalogs as baseCatalogs,
  defaultLocale,
  ensureModule,
} from '@bottlebooks/gatsby-theme-event/src/wrapElement/lingui/i18n.config';
import { i18n } from '@lingui/core';
import en from '../../../../locales/en';
import de from '../../../../locales/de';

const catalogs = {
  ...baseCatalogs,
  en: {
    messages: { ...baseCatalogs.en.messages, ...ensureModule(en).messages },
  },
  de: {
    messages: { ...baseCatalogs.de.messages, ...ensureModule(de).messages },
  },
};

export { locales } from '@bottlebooks/gatsby-theme-event/src/wrapElement/lingui/i18n.config';
export { defaultLocale };

export function load(locale) {
  if (!locale) return {};
  const catalog = catalogs[locale];
  i18n.load(locale, catalog.messages);
  return catalog.messages;
}
